<template>
    <div class="main">
        <div class="banner"></div>
        <div class="box">
            <h1 class="title-about" v-html="$t('engagement.title')"></h1>
            <p v-html="$t('engagement.text1')"></p>
            <div class="row card-row">
                <div class="card">
                    <img src="./../assets/logo-card-safe.png" class="card-logo" alt="logo safe" />
                    <p v-html="$t('engagement.ortho.text1')"></p>
                    <p v-html="$t('engagement.ortho.text2')"></p>
                    <a href="https://safeorthopaedics.com" target="_blank" class="btn">{{ $t('engagement.ortho.btn') }}</a>
                </div>
                <div class="card">      
                    <img src="./../assets/logo-card-med.png" class="card-logo" alt="logo safe" />          
                    <p v-html="$t('engagement.med.text1')"></p>
                    <p v-html="$t('engagement.med.text2')"></p>
                    <a href="https://safemedical.fr/" target="_blank" class="btn">{{ $t('engagement.med.btn') }}</a>
                </div>
            </div>
        </div>
        <div class="colored-section">
            <div class="box">
                <p v-html="$t('engagement.text2')"></p>
                <p v-html="$t('engagement.text3')"></p>
            </div>
        </div>
        <div class="box">
            <h1 class="title-about" v-html="$t('identity.title')"></h1>
            <div class="row">
                <div class="col-50">
                    <img src="./../assets/identity.png" alt="identity" class="identity-img" />
                </div>
                <div class="col-50">
                    <p v-html="$t('identity.text1')"></p>
                    <p v-html="$t('identity.text2')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "Engagement",
}
</script>

<style lang="scss" scoped>

    .banner {
        height:250px;
        width:100%;
        background-image: url("./../assets/bg-banner-2.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .main {
        padding-top: 0;
    }

    .col-50 {
        display:flex;
        flex-direction:column;
        align-items:center;
        margin: 40px 0;
    }

    .col-50 p {
        text-align:center;
        padding:0 20px;
        max-width:450px
    }

    p {
        font-size:18px;
    }

    .colored-section {
        padding:80px 0;
        background:$mainColor;
        margin-top:40px;
    }
    .colored-section p {
        text-align:justify;
    }

    .identity-img {
        max-width: 90%;
    }

    .card-row {
        justify-content: center;
    }
    .card {
        display: flex;
        flex-direction:column;
        align-items:center;
        width:450px;
        padding:20px;
        border:1px solid $mainColor;
        margin:40px;
    }

    .card-logo {
        margin-top:20px;
        margin-bottom: 40px;
        max-width: 90%;
    }

    .card p {
        text-align: center;
    }

    @media screen and (max-width:1200px) {
        .card-row {
            flex-direction: column;
            align-items: center;
        }
    }

    @media screen and (max-width:768px) {
        .identity-img {
            margin-bottom:20px;
        }
        
    }

    @media screen and (max-width:550px) {
        .card {
            width: 300px;
            margin:10px;
        }
        
    }
</style>